<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						@setFilters="setFilters"
					/>

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						:edit-by-alias="true"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler } from '@/mixins';
// import { orderStatusesList } from '@/constants/global';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.categories.fetchedMeta,
			filters: state => state.categories.filters,
			itemsLoading: state => state.categories.isLoading,
			itemsSaving: state => state.categories.isSaving,
			itemsList: state => state.categories.itemsList
		}),

		/*flatCategoriesList() {
			return this.$store.getters['categories/flatCategoriesList'];
		},*/

		itemsName() {
			return {
				one: 'Категория',
				mult: 'Категории'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: '№', sortable: true, width: 60 },
					{
						prop: 'title_ru',
						label: 'Название',
						sortable: true
					},
					{
						prop: 'parent_id',
						label: 'Родительская категория',
						sortable: false,
						meta: {
							getItemValue: { prop: 'title_ru', listName: 'categoriesList' },
							emptyText: ' - '
						}
					},
					{ prop: 'status', label: 'Статус', sortable: true }
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			// show_overlay: 'show_overlay',

			fetch_items: 'categories/fetch_categories',
			delete_item: 'categories/delete_category',

			set_items: 'categories/set_categories',
			set_filters: 'categories/set_categories_filters'
		})
	}
};
</script>
